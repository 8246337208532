.b1 {
   background-color: red;
   border: 1px solid red;
}
  
.b2 {
   background-color: green;
   border: 1px solid green;
}
  
.b3 {
   background-color: blue;
   border: 1px solid blue;
}
  
.b4 {
   background-color: purple;
   border: 1px solid purple;
}