@import "../../assets/styles/variables";

.container{
    width: 100%;
    padding: 70px 25px 25px 25px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(247, 247, 247);

    h2{
        width: fit-content;
        padding: 5px;
        font-size: 15px;
        background-color: $primary;
    }
}

.enSVRplus{
    text-transform: capitalize;
    font-size: 40px;
    margin: 10px 0px;
    font-weight: 600;
}

.nomAuteur{
    font-size: 20px;
    margin: 0px;
    span{
        border-bottom: 2px $primary solid;
        font-weight: bold;
        font-size: 22px;
    }
}

.presentation{
    font-size: 20px;
    margin: 0px;
    line-height: 40px; 
}


.experiences{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
        margin: 0;
        &:nth-child(1){
            width: 130px;
            height: 130px;
            font-size: 80px;
            font-weight: 500;
            padding: 20px;
            margin-top: 50px;
            border-radius: 100px;
            background-color: $primary;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &:nth-child(2){
            font-size: 28px;
            margin-top: 30px;

            span{
                font-weight: bold;
            }
        }
    }
}


.infoEcoordonnes{
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    div{
        display: flex;
        flex-direction: column;
        width: 43%;
        align-items: flex-start;
        p{
            margin: 0;
            &:nth-child(1){
                font-size: 15px;
                font-weight: 500;
                color: gray;
                margin-bottom: 3px;
            }

            &:nth-child(2){
                font-size: 15px;
                font-weight: 700;
                color: black;
                margin-bottom: 30px;
            }

        }

        &:nth-child(1){
            width: 57%;
        }
        &:nth-child(3){
            width: 57%;
        }
    }
}


@media screen and (min-width: 1000px) {
    .firstInfo{
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 80px;
        margin: 50px 0px;
    }

    .nomAuteur{
        span{
            font-size: 25px;
        }
    }

    .experiences{
        flex: 1;
        text-align: center;
        p{
            &:nth-child(1){
                margin: 0;
            }
            &:nth-child(2){
                font-size: 20px;
            }
        }
    }

    .infoEcoordonnes{
        div{
            margin: 0;
            width: 25% !important;
            p{
                &:nth-child(1){
                    font-size: 16px;
                    font-weight: 500;
                }
                &:nth-child(2){
                    font-size: 18px;
                }
            }
        }
    }
}