@import "../../assets/styles/variables";

.p-5 {
    padding: 5px;
}
  
.p-10 {
    padding: 10px;
}

.p-20 {
    padding: 20px;
}

.px-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.p-30 {
    padding: 30px;
}

.px-100{
    padding-left: 100px;
    padding-right: 100px;
}

.m-5 {
    margin: 5px;
}

.mr-5 {
    margin-right: 5px;
}

.m-10 {
    margin: 10px;
}

.mr-15 {
    margin-right: 15px;
}

.m-20 {
margin: 20px;
}

.mt-40{
    margin-top: 40px;
}

.mb-20 {
    margin-bottom: 20px;
}

.my-30 {
    margin-bottom: 30px;
    margin-top: 30px;
}

.mx-100{
    margin-left: 100px;
    margin-right: 100px;
}

.container {
    margin: auto;
    width: 100%;
}

.color_primary{
    color: $primary;
}



.margeResponsive{
    @media screen and (min-width: 1000px) {
        padding-left: 120px !important;
        padding-right: 120px !important;
    }
}

.headerBackground{
    background-color: white;
    z-index: 999 !important;
    border-bottom: 1px rgb(224, 224, 224) solid;
    transition: all 0.2s;
}


// .visibilitySection{
//     display: flex !important;
//     animation: Faiden 300ms 300ms ease-in-out both;
// }

// @keyframes Faiden {
//     0%{
//         opacity: 0;
//         transform: translateY(20px);
//     }
//     100%{
//         opacity: 1;
//         transform: translateY(0px);
//     }
// }