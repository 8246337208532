@import "../../assets/styles/variables";

.oneProject{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    overflow-y: scroll;
    padding: 30px 25px;
    background-color: white;
    border: 10px solid black;
    z-index: 9999 !important;
}

.sousTitre{
    text-transform: capitalize;
    font-size: 40px;
    margin: 10px 0px 50px 0px;
    font-weight: 600;
}


.detailsProject{
    text-align: left;
    .title{
        text-align: center;
        margin-top: 20px;
        font-size: 25px;
        display: flex;
        justify-content: center;
    }
    h3{
        display: flex;
        align-items: center;
        font-weight: 600;
        margin-top: 20px;
        p{
            font-size: 15px;
            margin: 0;
            margin-left: 10px;
            padding: 5px;
            background-color: $primary;
        }
        div{
            margin-left: 10px;
            font-size: 15px;
            font-weight: 400;
        }
    }
    p{
        font-weight: 300;
    }
}

.oneTechno{
  width: fit-content;
  background-color: rgb(216, 216, 216); 
  padding: 5px 15px;
  border-radius: 10px;
  font-size: 15px; 
  margin-right: 10px;
}

.allTechno{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
}

.iconeClose{
    width: 100%;
    position: fixed;
    top: 25px;
    left: 88%;
    z-index: 9999 !important;
    color: black;
    i{
        cursor: pointer;
        font-size: 25px;
    }
}

video{
    width: 100%;
    margin-top: 30px;
}

.containerView{
    width: 400px; 
    height: 400px;
    overflow: hidden;
    cursor: pointer;

    .hoverView{
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        
        div{
            display: none;
            .showDetails{
                margin-top: 20px !important;
                font-weight: 600;
                background-color: $primary;
                color: black;
                border: 2px solid white;
                border-radius: 10px;
                padding: 8px 30px;
            }
        }
        
        &:hover{
            transform: scale(1.1);
            transition: all .5s;
            div{
                font-size: 14px;
                font-weight: 300;
                padding: 0px 40px;
                display: flex !important;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                width: 400px; 
                height: 400px;
                color: white;
                background-color: rgba(0, 0, 0, 0.514);
                p{
                    margin: 0;
                }
            }
        }
    }
}

@media screen and (min-width: 800px) {
    .oneProject{
        border: 100px solid rgba(0, 0, 0, 0.400);
    }

    .urlProject{
        margin-bottom: 50px;
    }
}


@media screen and (min-width: 1000px) {
    .containerView{
        width: 355px; 
        height: 355px;
        .hoverView{                        
            &:hover{
                div{
                    width: 355px; 
                    height: 355px;
                }
            }
        }
    }

    video{
        width: 500px;
    }

    .demoEpresentation{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        margin-bottom: 40px;
    }
}
