.d-flex {
  display: flex;
}
  
.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-fill {
  flex: 1 1 auto;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-spacebetween{
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}
