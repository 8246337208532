@import "../../assets/styles/variables";

.container{
    width: 100%;
    padding: 70px 25px 100px 25px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(247, 247, 247);

    h2{
        width: fit-content;
        padding: 5px;
        font-size: 15px;
        background-color: $primary;
    }
}

.sousTitre{
    text-transform: capitalize;
    font-size: 40px;
    margin: 10px 0px 10px 0px;
    font-weight: 600;
}

.description{
    margin: 0;
    font-size: 17px;
    font-weight: 300;
}


.AllProjects{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.btnSeeMore{
    width: fit-content;
    text-align: center;
    border: 2px solid black;
    padding: 15px 25px;
    transition: all 300ms;
    &:hover{
        border-radius: 100px;
        background-color: $primary;
    }
}

.moreProjectContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 35px 10px 50px 10px;
}
