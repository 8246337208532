.Container{
   display: flex;
   justify-content: space-between; 
   align-items: center;
   padding: 8px 20px;
   position: fixed;
   width: 100%;
   height: 65px;
}

.LinkMenu{
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    width: 230px;
    color: black;
    font-size: 17px;

    a{
        color: black !important;
        font-weight: 600;
        @media screen and (max-width: 440px) {
            border: 3px solid black;
            padding: 5px 10px;
            border-radius: 100px;
        }
    }

    @media screen and (max-width: 440px) {
        span{
            display: none;
        }
    }
}

.MenuBurgerIcone{
    width: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    p{
        margin: 3px 0px;
        border: 1px solid black;
    }
}

.Logo{
    font-size: 30px;
    margin-right: 15px;
    span{
        color: transparent;
        -webkit-text-stroke: 1px black;
    }
}