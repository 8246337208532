body {
  margin: 0;
  font-family: $font-family;
  color: $text-color;
  background-color: $secondary;
}

html, body {
  touch-action: manipulation;
}
  
h1,
h2,
h3,
h4 {
  margin: 0;
}

* {
  box-sizing: border-box;
  font-family: $font-family;
}

ul {
  list-style: none;
  padding-left: 0px;
  margin: 0;
}

img {
  max-width: 100%;
}

a{
  text-decoration: none;
  color: $primary;
}

::-webkit-scrollbar {
  display: none;
}

.container{
  animation: fade-in 1.4s ease-in-out both;
}

@keyframes fade-in {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}


@media screen and (max-width: 799px) {
  .w_sp_800{
    display: none !important;
  }
}

@media screen and (min-width: 800px) {
  .w_inf_800{
    display: none !important;
  }
}