.Container{
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.932);
    color: white;
    font-size: 13px;
    z-index: 999;
    h2{
        font-weight: 500 !important;
        margin: 0px 0px 20px 0px;
        animation: FadinTop 800ms ease-in-out both;
    }
    a{
        color: white !important;
    }
}

.IconeReseaux{
    margin-top: 30px;
    width: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
    font-size: 28px;
}

.IconeClose{
    cursor: pointer;
    position: fixed;
    top: 10px;
    right: 30px;
    color: white;
    font-size: 30px;
}

@keyframes FadinTop {
    0%{
        transform: translateY(-40px);
    }
    100%{
        transform: translateY(0px);
    }
}