@import "../../assets/styles/variables";

.container{
    width: 100%;
    padding: 70px 25px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $primary;

    h2{
        font-size: 40px;
        text-transform: capitalize;
    }

    p{
        margin: 5px 0px;
        line-height: 35px;
        font-size: 20px;
        font-weight: 400;
    }
}


.coordonnes{
    margin-top: 50px;
    h3{
        font-size: 20px;
        font-weight: 600;
        margin-top: 20px;
    }
    p{
        
        font-weight: 300;
        font-size: 16px;
    }
}

.IconeReseaux{
    margin-top: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    color: rgb(51, 51, 51);
    i{
        font-size: 30px;
    }
}


.bottomFooter{
    background-color: rgb(31, 31, 31);
    color: white;
    padding: 50px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}